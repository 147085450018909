

@mixin generatorbutton {
  padding: 5px 11px 5px 15px;
  margin: 20px 0;
  cursor: pointer;
  transition: .2s;
  background: #5541D7;;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  svg {
    margin-right: 10px;
  }
  &:hover {
    background: #8476E3;
  }
  &:active {
    background: #342A76;
  }
}

.generator__resetinput {
  cursor: pointer;
  margin-left: 4px;
}

.add-field, .add-field-group {
  @include generatorbutton;

  &.badred {
    color: #FF6562;
    text-decoration: underline;
  }

  &.inside-field-group {
    margin: 10px 0 8px;
  }
}

.generator__mainarea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 27px;
}
.generator__field-group--wrapper {
  width: 100%;
}
.generator__field-group {
  margin: 25px auto;
  padding: 20px 15px ;
  background: #F6F6F6;
  border-radius: 14px;
  width: 100%;
  max-width: 508px;
  text-align: center;
  transition: .3s;

  display: flex;
  flex-direction: column;
  align-items: center;

  &.noTransition {
    transition: none;
  }
  &.translateDown {
    transition: .3s;
    transform: translateY(98px);
  }
  &.translateUp {
    transition: .3s;
    transform: translateY(-98px);
  }

  .field-change-title {
    text-align: center;
    height: 40px;
    width: 100%;
    max-width: 368px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    background: #FFFFFF url("data:image/svg+xml;utf8, #{$iconediturl}") no-repeat 73%;
    
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #232323;

    &::placeholder {
      color: #8B8F9A;
    }
    &:focus {
      background: #fff;
      &::placeholder {
        color:transparent;
      }
    }
    &.entitled {
      background: transparent;
      border: 1px solid transparent;
      &:focus {
        background: #fff;
        border: 1px solid #DDDDDD;
        &::placeholder {
          color:transparent;
        }
      }
    }
  }
}

.generator__field--wrapper {
  width: 100%;
  text-align: center;
}

.generator__field {
  position: relative;
  width: 100%;
  max-width: 368px;
  margin: 12px auto 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;

  &.noTransition {
    transition: none;
  }

  .generator__field-complete-icon {
    path{
      transition: .3s;
    }
    &:hover {
      path:first-child {
        fill: #75CBB7;
      }
    }
  }

  .field-change-icon {
    path {
      transition: .13s;
    }
    &:hover {
      path {
        fill: #6F737E;
      }
    }
  }
  .dragging-dots {
    position: absolute;
    top: 55%;
    left: -5%;
    cursor: grab;

    opacity: 1;
    visibility: visible;
    transition: .3s;
    path {
      transition: .13s;
    }

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      path {
        fill: #6F737E;
      }
    }
  }

  label {
    margin-bottom: 7px;
  }

  &.isActive {
    border-radius: 5px 5px 0px 0px;
    .input-wrapper {
      background: #F6F6F6;
      border: none;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 1px solid #5541D7;
      input {
        background: transparent;
      }
    }
  }
  
  &.inMotion {
    transition: opacity .3s;
    opacity: 0;
    visibility: hidden;
  }
  &.notInMotion {
    opacity: 1;
    visibility: visible;
  }

  &.translateDown {
    transition: transform .3s;
    transform: translateY(98px);
  }
  &.translateUp {
    transition: transform .3s;
    transform: translateY(-98px);
  }

  &.badred {
    .input-wrapper {
      border: 1px solid #FF6562;

      input::placeholder {
        color: #FF6562;
      }
    }
  }

  .title-complete {
    transition: none;
    width: 119px;
    height: 26px;
  
    margin-top: 15px;
    margin-right: auto;
  
    background: #5BC044;
    border-radius: 53px;
    border: none;
  
    font-weight: normal;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #FFFFFF;
  }

  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #232323;

    svg {
      margin-left: 4px;
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;

    width: 100%;
    background: #fff;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    transition: .4s;
    svg {
      margin-right: 8px;
      cursor: pointer;
    }

    input {
      padding-left: 15px;
      width: 100%;
      max-width: 285px;
      height: 40px;
      border: none;
      border-radius: 8px;
    }
  }
}

.generator__buttons {
  padding-left: 10px;
  width: 38%;
  display: flex;
  justify-content: space-around;

  &.active {
    div {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #fff;
      border-radius: 4px;
    }
  }
  &.inactive {
    div {
      transition: none;
      font-size: 14px;
      line-height: 18px;
      color: #A7A9AA;
      background: #ededed;
      cursor: default;
      pointer-events: none;
    }
    svg {
      path {
        fill: #A7A9AA;
      }
    }
  }

  div {
    @include generatorbutton;
  }
}

.save-template-modal-wrapper {
  position: absolute;
  left: 37%;
  top: 25%;

  text-align: center;
}
.save-template-modal {
  position: relative;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 40px 85px;
  z-index: 100;
  svg {
    margin-bottom: 30px;
  }
  h2 {
    padding: 0;
    margin-bottom: 8px;
  }
  h3 {
    margin-bottom: 30px;

    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5E5E5E;
  }
  .input-wrapper {
    margin-top: 18px;
    margin-bottom: 40px;

    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 8px;

    input {
      padding: 0 15px;
      width: calc(100% - 30px);
      height: 40px;
      border-radius: 8px;
      border: none;
    }
  }
}
.save-template-modal__buttons {
  display: flex;
  justify-content: space-around;
  button {
    width: 138px;
  }
}

.save-template-modal-bg-layer {
  background: #000;
  opacity: .3;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 800px) {
  .form-reset-button {
    display: none;
  }
  .generator__static-info {
    // display: flex;
    // align-items: center;
    // width: 100%;
    // justify-content: space-between;
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .generator__field-group {
  margin: 25px auto;
  padding: 20px 15px ;
  width: 100%;
  max-width: 385px;
  }
  .generator__field {
    max-width: 286px;
  }
  .add-field, .add-field-group {
    @include generatorbutton;
    margin: 0;
  }
}
@media (max-width: 900px) {
  .generator__field-group {
  margin: 25px auto;
  padding: 20px 15px ;
  width: 100%;
  max-width: 385px;
  }
  .generator__buttons {
    padding-left: 10px;
    width: 65%;
    max-width: 290px;
    min-width: 290px;
    display: flex;
    justify-content: space-between;
  
    div {
      margin: 16px 0;
    }
  }
  .generator__field {
    max-width: 286px;
  }
  // .add-field, .add-field-group {
  //   @include generatorbutton;
  //   margin: 0;
  // }
}


@media (max-width: 500px) {
  .generator__field-group {
  margin: 25px auto;
  padding: 20px 15px ;
  width: 100%;
  max-width: 315px;
  }
  .generator__field {
    max-width: 236px;
  }
}
@media (max-width: 400px) {
  .generator__field-group {
  margin: 25px auto;
  padding: 20px 15px;
  width: 100%;
  max-width: 250px;
  }
  .generator__field {
    max-width: 196px;
  }
}
@media (max-width: 376px) {
  .generator__field-group {
  margin: 25px auto;
  padding: 20px 15px ;
  width: 100%;
  max-width: 225px;
  }
  .generator__field {
    max-width: 180px;
  }
  .generator__buttons {
    max-width: 290px;
    min-width: 230px;
  
    div {
      font-size: 14px;
      margin: 14px 0;
      white-space: nowrap;
    }
  }
}

