@import './reset';
@import 'variables';

@import './components/all';

html,body, #root, .app {
  height:100%;
  width: 100%; }
body, .app {
  min-height: 100vh;
}
.app {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.content { 
  // min-height: 92%; 
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 70px);
  // overflow-y: auto;
  padding-bottom: 1px;
}

body {
  background-color: $background;
  font-family: 'Mulish', sans-serif;
}

// .input-wrapper:focus-within {
//   border: 1px solid rgba(85, 65, 215, 0.5);
// }

input, select, textarea, button{font-family:inherit;}

.container {
  height: 100%;
  margin: 0 auto;
  width: 1376px;
}

/* width */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
  margin: 40px;
  width: 1px;
  border: solid 3px transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 6px;
  height: 10px !important;
  background: #D1D2D7;
  border-radius: 50px;
  border: solid 3px transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}