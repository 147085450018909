.preset0-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  align-items: center;
  width: 100%;

  h3 {
    margin-top: 29px;

    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #232323;
  }
}
.preset-title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #232323;
}

.preset0-container__wp-admin {
  margin-right: 50px;
}
.preset0-container__ftpKeys {
  .generator__field:nth-child(2) {
    
  }
}
.preset0-container__wp-admin,
.preset0-container__ftpKeys {
  display: flex;
  flex-direction: column;
  align-items: center;

  .generator__field {
    padding: 10px 37px 15px 22px;
    margin: 15px 0 10px;

    input {
      max-width: 260px;
    }
  }

  h3 {
    margin-top: 45px;
    font-size: 20px;
    line-height: 25px;
    color: #5E5E5E;
  }
}

// REQUEST LINK PAGE

.requestlink__form-wrapper {
  display: flex;
  text-align: center;
  width: 100%;
  max-width: 1120px;

  .preset0-rlform {
    h3 {
      margin-top: 15px;
      font-size: 20px;
      line-height: 25px;
      text-align: center;

      svg {
        margin-left: 9px;
        &:hover {
          path {
            transition: .4s;
            fill: grey;
          }
        }
      }
    }

    .connection-type__port {
      display: flex;
      .requestlink__field:first-child {
        margin-right: 20px;
        .input-wrapper {
          input {
            width: 100vw;
            max-width: 170px;
          }
        }
      }
      .requestlink__field:nth-child(2) {
        .input-wrapper {
          input {
            width: 100vw;
            max-width: 170px;
          }
        }
      }
    }
  }
}

.preset-title__tooltip-block {
  position: relative;
  margin-top: 8px;
}

@media (max-width: 1376px) {
  .requestlink__form-wrapper {
    .preset0-rlform:first-child {
      margin-right: 25px;
    }
  }
}
@media (max-width: 1146px) {
  .requestlink__form-wrapper {
    box-sizing: content-box;
    flex-direction: column;
    align-items: center;
    .preset0-rlform {
      margin: 0 auto;
    }
    .preset0-rlform:first-child {
      margin:0 auto 25px;
    }
  }
}
@media (max-width: 750px) {
  .requestlink__form-wrapper {
    .preset0-rlform {
      margin: 0 auto;
    }
  }
}
@media (max-width: 511px) {
  .requestlink__form-wrapper {
    .preset0-rlform {
      margin: 0 auto;
    }
  }
}
@media (max-width: 401px) {
  .requestlink__form-wrapper {
    .preset0-rlform {
      margin: 0 auto;
    }
  }
}
@media (max-width: 350px) {
  .requestlink__form-wrapper {
    .preset0-rlform {
      margin: 0 auto;
    }
  }
}