.inner__content--profile {
  padding: 0 54px 30px 30px;
  //margin-bottom: 25px;
  background: #FFFFFF;
  box-shadow: -10px 9px 21px rgba(35, 35, 35, 0.07);
  border-radius: 20px;
  height: 100vh;
  max-height: calc(100vh - 180px);
  width: 100%;
  max-width: 1028px;
  overflow-y: auto;
}
.profile-utility__email-address, .profile-utility__password-for-email {
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #232323;
    }

    .input-wrapper {
      margin-top: 8px;
      input {
        padding: 0 15px;
        width: 100%;
        max-width: 338px;
        border: 1px solid #dddddd;
        height: 40px;
        border-radius: 8px;

        transition: 0.3s;
        &.badRed {
          border: 1px solid #ff6562;
        }
      }
    }
  
}

.profile__user-characteristics {
  display: flex;
  width: 100%;
  padding-top: 30px;
  .user-characteristics__block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 30px;
    div:first-child {
      margin-top: 0;
    }
  }
}
.profile__submit-buttons {
  // margin-top: 35px;
  display: flex;
  margin-bottom: auto;
  .button.purple {
    min-width: 172px;
    margin-right: 20px;
  }
  .button.white {
    min-width: 172px;
  }
}

.avatar-crop-modal-wrapper {
  position: absolute;
  top: 12%;
  left: 50%;
  margin-left: -262px;
  z-index: 50;
}
.avatar-crop-modal {
  width: 100vw;
  max-width: 524px;
  max-height: 614px;
  overflow: auto;
  position: relative;
  z-index: 100;
  border-radius: 10px;
  padding: 25px 30px;
  background: #fff;
  text-align: center;
  h2{
    padding-top: 0 !important;
    text-align: left;
    margin-bottom: 19px;
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #232323 !important;
  }
  .ReactCrop__image {
    position: relative;
    width: 100vw;
    max-width: 461px;
    max-height: 100vw;
    overflow-y: scroll;
    //max-height: 424px;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 8px;
  }

  .avatar-crop-modal__buttons {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    .button.white {
      margin-right: 23px;
    }
    .button {
      width: 150px !important;
    }
  }
}
.avatar-crop-modal-bg-layer {
  background: #000;
  opacity: .3;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.profile-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-avatar__img {
  width: 186px;
  height: 186px;
  border-radius: 8px;
  position: relative;

  cursor: pointer;

  &.noPic {
    background: #F6F6F6 url("data:image/svg+xml;utf8, #{$changeimghoverurl}") no-repeat 50% 50%;
  }

  img {
    object-fit: cover;
    width: 186px;
    height: 186px;
    border-radius: 8px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    background: rgba(0,0,0,.1);
    opacity: 0;
    transition: .2s;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("data:image/svg+xml;utf8, #{$changeimghoverurl}") no-repeat 50% 50%;
    opacity: 0;
  }

  &:hover::before {opacity:  1;}
  &:hover::after {opacity: 1;}

}

.profile-avatar__select {
  margin-left: 16px;
  span {
    display: block;
    margin-bottom: 23px;

    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #232323;
  }
}

.profile-avatar__select-buttons {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .button {
    min-width: 138px;
    margin-right: 10px;
    &.transparent {
      margin-top: 10px;
      color:#5E5E5E;

    }
  }
}

@mixin settingsProfileForms {
  margin-top: 24px;
  label {
    // margin-left: 16px;
  }
  input {
    padding:0 15px;
    border: none;
    width: calc(100% - 47px);
    height: 40px;
    border-radius: 8px;
  }
  .input-wrapper {
    min-width: none;
    padding: 0 2px;
    margin-top: 8px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    transition: .3s;
    &.goodGreen {
      border: 1px solid #5BC044;
    }
    svg {
      margin-left: auto;
      margin-right: 20px;
    }
  }
}

.profile-name {
  @include settingsProfileForms;
}
.profile-phone {
  @include settingsProfileForms;
}
.profile-city {
  @include settingsProfileForms;
}
.profile-postal {
  @include settingsProfileForms;
}
.profile-address {
  @include settingsProfileForms;
}

.profile-state-province {
  @include settingsProfileForms;
  input {
    width: calc(100% - 74px);
  }
  .input-wrapper {
    max-height: 42px;
    position: relative;
    display: flex;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
}
.profile-country {
  @include settingsProfileForms;
  input {
    width: calc(100% - 74px);
  }
  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
}

.billing-zip {
  margin-bottom: 30px;
}

.states-dropbox {
  border: 1px solid #D3D8E1;
  border-radius: 44px;
  background: #FFFFFF;
  border-radius: 8px;
  max-height: 450px;

  svg {
    transform: rotate(180deg)
  }

  position: absolute;
  top: -2%;
  left: -1px;

  display: none;

  .input-wrapper {
    margin-top: 0;
    border: none;

    &:focus-within {
      border: none;
    }
  }

  .countries-dropbox__list {
    overflow-y: auto;
  }

  &.isActive {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.countries-dropbox__list {
  padding-right: 2px;
  margin-right: 2px;
  overflow-y: auto;
}
.countries-dropbox {
  border: 1px solid #D3D8E1;
  border-radius: 44px;
  background: #FFFFFF;
  // box-shadow: -10px 10px 25px rgba(35, 35, 35, 0.25);
  border-radius: 8px;

  max-height: 450px;

  svg {
    transform: rotate(180deg)
  }

  position: absolute;
  z-index: 100;
  top: -2%;
  left: -1px;

  display: none;

  .input-wrapper {
    margin-top: 0;
    border: none;
    &:focus-within {
      border: none;
    }
  }


  &.isActive {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.countries-dropbox__item {
  border-right: 1px solid rgb(224, 223, 223);

  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  text-align: left;
  letter-spacing: 0.005em;
  color: #232323;
  padding: 16px 0 15px 16px;
 
  cursor: pointer;
  &:hover {
    background: rgb(230, 228, 228);
  }
  &:last-child:hover {
    background: rgb(230, 228, 228);
    border-radius: 0 0 0 7px;
  }

  &.no-match {
    font-size: 16px;
    font-weight: 400;
    pointer-events: none;
    cursor: default;
    &:hover {
      background:#fff;
    }
  }
}

.profile-utility {
  width: 100vw;
  max-width: 946px;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #EDEDED;
}

.profile-utility__contact-info, .profile-utility__change-password, .profile-utility__delete-account {
  max-width: 388px;
  h3 {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #232323;
  }
  h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #505356;
  }
  .input-wrapper {
    margin-bottom: 20px;
  }
}
.profile-utility__contact-info {
  width: 50%;
  padding-right: 30px;
  border-right: 1px solid #EDEDED;
  h4 {
    margin-bottom: 25px;
  }
  .button {
    min-width:172px;
  }
}
.profile-utility__change-password {
  width: 23%;
  margin-left: 30px;
  a {
    text-decoration: none;
  }
  h4 {
    margin-bottom: 30px;
  }
}
.profile-utility__delete-account {
  margin-left: 30px;
  padding-left: 30px;
  width: 25%;
  height: 55%;
  border-left: 1px solid #EDEDED;
  h4 {
    margin-bottom: 10px;
  }
}

@media (max-width: 1151px) {
  .billing-zip {
    margin-bottom: 0;
  }
  .profile-utility {
    flex-direction: column;
  }
  .profile__user-characteristics {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    // .profile__submit-buttons {
    //   margin-top: 20px;
    // }
    .profile-avatar {
      order: 1;
      flex-direction: row;
      align-items:flex-start;
    }
    .user-characteristics__block {
      order: 2;
      margin-right: 0;
      &:first-child {
        margin-bottom: 25px;
      }
    }
  }
  .profile-avatar__img {
    width: 81px;
    height: 81px;
    img {
      object-fit: cover;
      width: 81px;
      height: 81px;
      border-radius: 8px;
    }
  }
}
@media (max-width: 1000px) {
  .profile-utility__contact-info, .profile-utility__change-password {
    max-width: none;
    margin-bottom: 30px;
    margin-left: 0;
      }
  .profile-utility__contact-info {
    border-right: none;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 30px;
  }
  .inner__content--profile {
    padding: 0 15px 0;
  }
  .profile__user-characteristics { 
    padding-top: 15px;
    .user-characteristics__block {
      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
  .profile__submit-buttons {
    display: flex;
    justify-content: center;
    .button {
      max-width: 150px;
      padding: 11px 14px;
      &.purple {
        margin-right: 12px;
      }
    }
  }
  .profile-name {
    margin-top: 15px;
  }
  .profile-phone {
    margin-top: 15px;
  }
  .profile-city {
    margin-top: 15px;
  }
  .profile-postal {
    margin-top: 15px;
  }
  .profile-address {
    margin-top: 15px;
  }
  .profile-country {
    margin-top: 15px;
  }
}

