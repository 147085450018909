.remember-forgot {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.incorrectNewPassword {
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #FF6562;

  text-align: left;

  visibility: hidden;
  opacity: 0;
  transition: .3s;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}
.email_question {
  margin-top: 30px;
  span {
    margin-right: 15px;

    font-weight: normal;
    font-size: 16px;
    line-height: 120%;

    letter-spacing: 0.005em;
    color: #5E5E5E;
  }

}
.login__static {
  width: 45%;
  position: relative;

  border-radius: 0 20px 20px 0;

  background: 
              url("data:image/svg+xml;utf8, #{$rect7url}") no-repeat 102% 0%,
              url("data:image/svg+xml;utf8, #{$rect6url}") no-repeat top 190px right 11px,
              url("data:image/svg+xml;utf8, #{$rect5url}") no-repeat 82px 86px,
              url("data:image/svg+xml;utf8, #{$rect4url}") no-repeat 69px 98px,
              url("data:image/svg+xml;utf8, #{$rect3url}") no-repeat 102% 95%,
              url("data:image/svg+xml;utf8, #{$rect2url}") no-repeat 82% 88%,
              url("data:image/svg+xml;utf8, #{$rect1url}") no-repeat 66% 75%,
              url("data:image/svg+xml;utf8, #{$bg-shapeurl}") no-repeat 0 -40px,
              linear-gradient(298deg, rgba(242,244,248,1) 23%, rgba(255,255,255,1) 100%);

  .static__behind-block {
    position: absolute;
    top: 237px;
    left: 92px;
    box-shadow: -15px 5px 20px rgba(54, 35, 123, 0.1);

    .behind__purple-arrow {
      position: absolute;
      top: -29px;
      left: -19px;
    }

    .behind__list {
      background: url("data:image/svg+xml;utf8, #{$purplePlusUrl}") no-repeat 120px 34px;
      padding-top: 90px;
      .list__item-first {
        margin-left: 2px;
        span {
          padding-left: 1px;
        }
        .behind__greendot {
          margin-left: 33px;
        }
      }
      .list__item {
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #5E5E5E;

        display: flex;
        align-items: center;
        padding: 0 18px;
        margin-bottom: 23px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
  .static__front-block {
    position: absolute;
    top: 204px;
    left: 239px;
    box-shadow: 10px 10px 15px rgba(35, 35, 35, 0.1);
    .front__orange-arrow {
      position: absolute;
      bottom: -95px;
      right: -23px;
    }
    .front__header {
      .header__motto {
        padding: 37px 105px 38px 17px;

        font-weight: 300;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #232323;

        background: url("data:image/svg+xml;utf8, #{$frontHeaderBgUrl}") no-repeat 100% 0px;
      }
    }
    .front__footer {
      padding: 45px 0;
      border-radius: 0 0 5px 5px;
      box-shadow: -10px 10px 20px rgba(35, 35, 35, 0.1);

      background: url("data:image/svg+xml;utf8, #{$frontFooterGrayLinesUrl}") no-repeat 17px 11px,
                  url("data:image/svg+xml;utf8, #{$frontFooterSendIconUrl}") no-repeat bottom 25px right 8px,
                  rgb(239, 242, 247);
    }
  }
  .static__inner-block {
    background: #FFF;
    border-radius: 7px;
  }
}
.login__static-motto {
  position: absolute;

  top: 35%;
  left: 43.5%;


  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #232323;
}
.login-footer {
  margin: 20px auto;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;

  text-align: center;
  letter-spacing: 0.005em;

  color: #5E5E5E;
  a {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .button.white.login-footer__button {
    margin: 0 auto;
  }
}

//@media (max-height: 920px) {
//  .login__actions {
//    &.type--signup {
//      .button {
//        margin-top: 8px;
//      }
//      .login__forms {
//        padding-top: 120px;
//      }
//    }
//    &.type--resetpass {
//      background: url("data:image/svg+xml;utf8, #{$typeresetpass13iurl}") no-repeat 50% 7%;
//      .button {
//        width: 182px;
//      }
//      .login__forms {
//        padding-top: 160px;
//      }
//    }
//    min-height: 580px;
//  }
//  .login__static {
//    .static__front-block {
//      position: absolute;
//      top: 202px;
//      left: 216px;
//      .front__header {
//        .header__motto {
//          padding: 37px 75px 38px 17px;
//
//          font-weight: 300;
//          font-size: 16px;
//          line-height: 120%;
//          letter-spacing: 0.005em;
//          color: #232323;
//        }
//      }
//      .front__footer {
//        padding: 45px 0;
//        background-color: #eff2f7;
//        border-radius: 0 0 5px 5px;
//      }
//    }
//    .static__behind-block {
//      position: absolute;
//      top: 237px;
//      left: 71px;
//    }
//  }
//}
//@media (max-height: 756px) {
//  .login {
//    margin-bottom: 15px;
//  }
//}
//
//@media (max-width: 1024px) {
//    .login {
//      margin: 30px 20px 0;
//    }
//    .login__static {
//      display: none;
//    }
//    .login__actions {
//      width: 100%;
//      .login__forms {
//        margin: 0 auto;
//      }
//      &.type--emailsent {
//        background: url("data:image/svg+xml;utf8, #{$typeresetpassurl}") no-repeat 50% 7%;
//        .login__forms {
//          padding: 200px 15px;
//          margin: 0 auto;
//        }
//      }
//    }
//    .login-footer {
//      display: flex;
//      flex-direction: column;
//      align-items: center;
//      .button {
//        margin-top: 10px;
//      }
//    }
//}