.requestlink {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 100%;
  height: 100%;
  overflow-y: hidden;

  .requestlink__isnotloading {
    width: 100%;
    height: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }

  button {
    text-align: center;
    min-width: 172px;
  }
}

.requestlink__header-requestby {
  display: flex;
  margin: 0 auto 15px;
  padding: 18px 10px;
  max-width: 409px;
  max-height: 109px;
  background: #FFFFFF;
  box-shadow: -6.87129px 6.18416px 14.4297px rgba(35, 35, 35, 0.07);
  border-radius: 10px;

  .header-requestby__img {
    img {
      width: 85px;
      height: 85px;
      border-radius: 6px;
      object-fit: cover;
    }
    svg {
      height: 85px;
      width: 85px;
    }
  }

  .header-requestby__titles {
    text-align: left;
    margin-left: 12px;
    h2 {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.005em;
      color: #232323;
    }
    h3 {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.005em;

      color: #5E5E5E;

    }
  }
}

.keys-request-by {
  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
  }
}

.requestlink__mainarea {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 15px;

  width: 100%;
  max-width: 1376px;

  // height: 100vh;
  // min-height: 300px;
  max-height: calc(100vh - 80px);

  overflow-y: auto;
  overflow-x: hidden;
  

  .button.purple {
    margin: 15px auto;
  }
}

.requestlink__form {
  padding: 30px 10px 5px;
  margin: 0 auto 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 100%;
  max-width: 480px;
  max-height: calc(100% - 20px);
  overflow-x: hidden;
  overflow-y: scroll;
  background: #FFFFFF;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.need-help-button--mobile {
  display: none;
}

.requestlink__field {
  position: relative;
  text-align: start;
  margin-bottom: 25px;
  width: 100%;
  max-width: 420px;
  label {
    margin-left: 3px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #232323;
  }

  .input-wrapper {
    margin-top: 5px;
    text-align: center;
    border: 1px solid #DDDDDD;
    background: #FFFFFF;
    border-radius: 8px;

    transition: .2s;
    input {
      padding: 0 15px;
      width: calc(100% - 30px);
      max-width: 370px;
      height: 40px;
      border: none;
      border-radius: 8px;

      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.005em;

      color: #232323;

      &.withDropDown {
        background: url("data:image/svg+xml;utf8, #{$arrowdownurl}") no-repeat 95% 50%;
        cursor: pointer;
      }
    }

    &.badred {
        border: 1px solid #FF6562;
        input::placeholder {
          transition: .2s;
          color: #FF6562;
        }
      
    }
  }
}

.ftp-sftp-dropdown {
  position: absolute;
  z-index: 20;
  top: 23px;
  left: 0;
  width: 100%;
  max-width: 200px;
  //min-width: 148px;
  border: 1px solid #DDDDDD;
  background: #FFFFFF;
  border-radius: 8px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #232323;
  font-size: 15px;
  svg {
    margin-right: 10px;
  }
  div {
    display: flex;
    align-items: center;
    min-height: 40px;

    cursor: pointer;

    span {
      margin-left: 15px;
    }
  }
  .ftp-sftp-dropdown__current {
    background: url("data:image/svg+xml;utf8, #{$arrowdownurl}") no-repeat 85% 50%;
    border-bottom: 1px solid #DDDDDD;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.005em;

    color: #232323;
  }
  .ftp-sftp-dropdown__ftp,
  .ftp-sftp-dropdown__sftp {
    &:hover {
      background: #DDDDDD;
    }
  }
}

.requestlink__fieldgroup {
  h4 {
    margin: 12px 0px;
    display: block;
    font-weight: bold;
    font-size: 17px;
    line-height: 16px;
    color: #232323;
  }

  width: 85%;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F6F6F6;
  border-radius: 14px;
}

@media (max-width: 676px) {
  .need-help-button {
    display: none;
  }
  .need-help-button--mobile {
    display: flex;
  }
  .keys-request-by {
    display: none;
  }
  .requestlink {
    .requestlink__isnotloading {
      width: 90%;
      // padding: 0 15px;
    }
  }
 
  .requestlink__form {
    max-width: 375px;
  }
  .requestlink__header-requestby {
    max-width: 345px;
  }
}

@media (max-width: 460px) {
 .erase-on-media-querry {
   display: none;
 }
 .requestlink__form {
  max-width: 345px;
  } 
  .requestlink__header-requestby {
    max-width: 315px;
  }
}

@media (max-width: 424px) {
  .requestlink__form {
   max-width: 315px;
   } 
   .requestlink__header-requestby {
     max-width: 285px;
   }
}


@media (max-width: 374px) {
  .requestlink__form {
   max-width: 270px;
   } 
   .requestlink__header-requestby {
     max-width: 240px;
   }
 }



